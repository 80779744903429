$primary-color: #ce202e;
$white-color: #ffffff;

.blackSection:nth-child(1):after {
  background-image: url("../../assets/shape.png");
  content:"";
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
  width: 200px;
  height: 200px;
  position: absolute;
  left: -130px;
  top: -110px;
  transform: rotate(180deg);
}