@import url('https://fonts.googleapis.com/css2?family=Armata&display=swap');

$primary-color: #ce202e;
$white-color: #ffffff;
$card-color: #222222;
$grey-color: #333333;


body {
  background: #000000;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  font-size: 18px;
}

.element-layout {
  margin-top: 40px;
  background: $grey-color;
}


.checkoutButton:disabled{
  background: grey!important;
  font-size: 0px;
}
.checkoutButton:disabled:before{
  content: 'SOLD OUT';
  font-size: 16px;
}
.checkoutButton:disabled:hover{
  cursor: no-drop;
}

.checkoutButton {
  background: $primary-color;
  color: $white-color;
  text-transform: uppercase;
  margin: 10px;
  @media (max-width: 991px) {
    display: inline-block;
    float: left;
  }
  @media (max-width: 400px) {
      width: 210px;
      margin-left: calc(50% - 105px)
  }
}

.checkoutButton:hover{
  background: #ffffff;
  color: #666666;
}

.btn {
  border: 0px solid transparent;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}
.myContainer{
  max-width: 1300px;
  margin: auto;
}
.productNameWrapper{
  width: 100%;
}
.productLineWrapper{
  display: grid;
  grid-template-columns: 1fr 542px;
  @media (max-width: 1199px) {
    grid-template-columns: 1fr 240px;
    border-bottom: 1px solid #666666;
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  .line{
    background: #666666;
    @media (max-width: 991px) {
      display: none;
    }
  }
}
.product-row {
  display: grid;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  padding: 10px;
  grid-template-columns: 1fr 160px ;
  @media (max-width: 1199px) {
    grid-template-columns: 1fr 130px;
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    justify-items: center;
    display: block;
    float: left;
  }
  column-gap: 10px;
  /*grid-template-areas: 'title title qty price'
  'description description qty price';*/
  align-items: center;
  justify-items: left;
  @media (max-width: 991px) {
  }
  .fieldOne {
    //grid-area: title;
    text-align: left;
    @media (max-width: 991px) {
      text-align: center;
    }
    input {
      font-family: 'Armata', sans-serif;
      border: 0px solid transparent;
      background: transparent;
      color: #ffffff;
      font-size: 30px;
      @media (max-width: 991px) {
        text-align: center;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .fieldTwo {
    //grid-area: description;
    text-align: left;
    @media (max-width: 991px) {
      margin-bottom: 10px;
    }

    input {
      font-family: 'Armata', sans-serif;
      border: 0px solid transparent;
      background: transparent;
      color: $primary-color !important;
      font-size: 13px !important;
      width: 100%;
      @media (max-width: 991px) {
        text-align: center;
      }
      @media (max-width: 767px) {
        font-size: 14px !important;
        width: 100%;
      }
    }
  }

  .fieldThree {
    //grid-area: qty;
    align-items: center;
    span {
      font-size: 22px;
      @media (max-width: 991px) {
        font-size: 16px;
      }
    }

    @media (max-width: 991px) {
    display: block;
    float: left;
    margin-left: calc(50% - 50px);
    }

    input {
      border: 1px solid #111111;
      background: #111111;
      color: #ffffff;
      font-size: 20px;
      padding: 10px;
      border-radius: 10px;
      width: 100px;
      @media (max-width: 1199px) {
        width: 70px;
      }
      @media (max-width: 991px) {
        text-align: center;
        font-size: 16px;
      }
    }
  }

  .fieldFour {
    //grid-area: price;
    align-items: center;
    position: relative;
    @media (max-width: 991px) {
      display: block;
      float: left;
      padding-left: 10px;
      }
    @media (max-width: 767px) {
      margin-left: 20px;
    }

    span {
      font-size: 24px;
      @media (max-width: 991px) {
        font-size: 18px;
      }
    }

    input {
      border: 0px solid transparent;
      background: transparent;
      color: #ffffff;
      font-size: 24px !important;
      text-align: left;
      width: 100px;
    
      @media (max-width: 991px) {
        text-align: left;
        width: 70px;
        font-size: 18px!important;
      }
      @media (max-width: 767px) {
        width: 95px;
      }

    }
  }
}

.shipping-details {
  background: #333333;
  border-radius: 10px;
  max-width: 1200px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 20px;
  display: inline-block;
  width: 100%;
  @media (max-width: 991px) {
    width: calc(100% - 40px);
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  /*grid-template-areas: 'firstName lastName'
    'street city'
    'state postcode';

  @media (max-width: 991px) {
    grid-template-areas: 'firstName firstName'
      'lastName lastName'
      'street street'
      'city city'
      'state postcode';
    margin: 0px;
    width: calc(100% - 40px);
    margin-bottom: 30px;
  }*/

  column-gap: 20px;
  row-gap: 20px;
  justify-items: center;
  input{
    background: #111111;
    border-radius: 5px;
    border: 0px solid transparent;
    width: 100%;
    color: #ffffff;
    padding: 15px!important;
  }

  .text-form-field {
    width: 100%;

    label {
      display: grid;
      grid-template-columns: 120px 1fr;
      align-items: center;
      @media (max-width: 991px) {
        grid-template-columns: auto;
      }
      input {
        width: auto;
        padding: 10px;
        font-size: 18px;
      }
    }
  }
}

.cartHeaderTitle{
  max-width: 1200px;
  margin: auto;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: $primary-color;
  font-weight: bolder;
  align-items: center;
  div{
    text-align: left;
    padding-left: 20px;
  }
}

.cartItem{
  border: 1px solid #cccccc;
  max-width: 1200px;
  margin: auto;
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  div{
    text-align: left;
    padding-left: 20px;
  }
}
.cartItem:nth-last-child(1){
  margin-bottom: 20px;
}

.emailField{
  label{
    grid-template-columns: 1fr!important;
  }
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.firstNameField{
  label{
    grid-template-columns: 1fr!important;
  }
  width: calc(50% - 10px);
  float: left;
  margin-bottom: 10px;
  margin-right: 20px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.lastNameField{
  label{
    grid-template-columns: 1fr!important;
  }
  width: calc(50% - 10px);
  float: left;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}
.streetField{
  label{
    grid-template-columns: 1fr!important;
  }
  width: 100%;
  float: left;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.cityField{
  label{
    grid-template-columns: 1fr!important;
  }
  width: calc(33.3% - 13px);
  float: left;
  margin-bottom: 10px;
  margin-right: 20px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.stateField{
  label{
    grid-template-columns: 1fr!important;
  }
  width: calc(33.3% - 13px);
  float: left;
  margin-bottom: 10px;
  margin-right: 20px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.postcodeField{
  label{
    grid-template-columns: 1fr!important;
  }
  width: calc(33.3% - 13px);
  float: left;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.phoneField{
  label{
    grid-template-columns: 1fr!important;
  }
  width: 100%;
  float: left;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.instructionField{
  label{
    grid-template-columns: 1fr!important;
  }
  width: 100%;
  float: left;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.paymentWrappers{
    max-width: 1240px;
    margin: auto;
    display: grid;
    grid-template-columns: 200px 1fr 200px;
    row-gap: 10px;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }
    margin-bottom: 30px;
    column-gap: 20px;
    align-items: center;

}

.payment-details{
  background: $white-color;
  border-radius: 10px;
  padding: 20px 20px;
}
.payButton{
  text-transform: uppercase;
  border-color: $primary-color;
  border-style: solid;
  border-width: 1px;
  color: $primary-color;
  background: #111111;
  height: 100%;
}


.cardLogos {
  background-image: url("../../assets/secure-stripe-payment.png");
  background-size: 100% 100%;
  background-position: center center;
  position: relative;
  overflow: hidden;
  padding: 0px 0px;
  height: 24px;
  @media (max-width: 991px) {
    height: auto;
  }
}



/*LANDING PAGE STYLE*/

body {
  font-family: 'Open Sans', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Armata', sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;

}

h1{
  font-size: 40px;
}

p{
  margin-top: 10px;
  margin-bottom: 10px;
}

.blackSection:nth-child(2):after {
  content: '';
  background-image: url("../../assets/shape.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
  width: 200px;
  height: 200px;
  position: absolute;
  left: -130px;
  top: -110px;
  transform: rotate(180deg);
}
.blackSection {
  background-color: #000000;
  position: relative;
}
.greySection {
  background-color: #333333;
  position: relative;
  color: #ffffff;
}
.footerSection{
  background: #ce202e;
  padding: 20px 0 20px 0;
  position: relative;
}
.footerSection p{
 margin-bottom: 0px;
 color: #ffffff;
 text-align: left;
 @media (max-width: 767px) {
   margin-top: 50px;
 }
}
.footerSection a{
  color: #ffffff;
  text-decoration: underline;
}
.footerSection a:hover{
  color: #000000;
}
.selectBar {
  position: relative;
  overflow: hidden;
  height: 30px;
  background-image: url("../../assets/selectBar.svg");
  background-size: 5000px auto;
  background-repeat: no-repeat;
  background-position: left calc(50% - 30px) bottom 0px;
  z-index: 20;
}
.beefSection {
  background-image: url("../../assets/beefSection.jpg");
  background-size: cover;
  background-position: center 35%;
  position: relative;
  overflow: hidden;
  top: -15px;
  z-index: 1;
  color: #ffffff;
  padding-top: 100px;
  padding-bottom: 100px;
  font-weight: normal;
  margin-bottom: -15px;
}
.price{
  font-size: 46px;
  color: #ffffff;
}

.price span{
  font-size: 22px;
}

.borderRight{
border-right: 1px solid #ffffff;
}

@media (max-width: 767px) {
  .borderRight{
    border-right: 0px solid #ffffff;
    }
}

.limitedOfferBar{
  background: #ce202e;
  position: absolute;
  padding: 2px 50px;
  top: 45px;
  right: -50px;
  transform: rotate(45deg);
  color: #ffffff;
}

.btn{
  padding: 14px 10px;
}

a:hover .btn{
background: #eeeeee!important;
color: #666666!important;
}
.buttonGroup{
  display: flex;
  height: 100%;
  align-items: center;
}
.interestForm{
  background: #222222;
  padding-top: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.interestForm input{
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 0px solid transparent;
}

.interestForm .button{
  background: #ce202e;
  color: #ffffff;
}

.interestForm .button:hover{
  background: transparent;
  box-shadow: 0px 0px 0px 1px #ce202e;
  color: #ffffff;
  cursor: pointer;
}

.link{
  color: #ce202e;
  text-decoration: underline;
}
.link:hover{
  color: #ffffff;
}

.withDepostiOffer{
  position: absolute;
  top: 30px;
  left: 0px;
  font-size: 12px!important;
  color: #ce202e;
  @media (max-width: 767px) {
    margin-left: 20px;
  }
}

.regButton{
  @media (max-width: 767px) {
    display: inline-block;
    float: left;
  }
  @media (max-width: 991px) {
    display: inline-block;
    float: left;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}

.mobileRow{
  @media (max-width: 767px) {
    margin-left: 0px!important;
    margin-right: 0px!important;
  }
}

.blockChainWrapper{
  body{
    display: none;
  }
}

.myClassname[style]{
  @media (max-width: 767px) {
  height: 2800px!important;
  }
}
.field-error {
  color: #be1f31;
  border-radius: 3px;
  margin-top: 5px;
  font-size: 13px;
}