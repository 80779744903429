
.backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;

  .spinner-panel {
    color: white;
    margin: auto;
  }

}

