$primary-color: #ce202e;
$white-color: #ffffff;

.header {
  background-image: url("../../assets/headerBG.jpg");
  background-size: cover;
  background-position: center 20%;
  position: relative;
  overflow: hidden;
  padding: 50px 0px;

  .headerLogo {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .img-logo {
      width: 250px;
      margin-bottom: 15px;
    }

    .img-banner {
      width: 400px;
    }
  }
}


.header:before {
  content: '';
  background-image: url("../../assets/shape.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  width: 300px;
  height: 300px;
  position: absolute;
  right: -130px;
  top: -140px;
}